import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../containers/Layout/Layout';
import HeroTitle from '../components/HeroTitle/HeroTitle';
import ImageBox from '../components/ImageBox/ImageBox';
import SEO from '../components/SEO';
import { Wrapper } from '../styles/pages/services.css';
import { map } from 'lodash';

const Gallery = ({ data }) => {
  return (
    <Layout>
      <SEO meta={data.datoCmsGalleryPage.seoMetaTags} />
      <HeroTitle
        title={data.datoCmsGalleryPage.title}
        image={data.datoCmsGalleryPage.featuredImage.fluid}
      />
      <Wrapper>
        {map(data.datoCmsGalleryPage.gallery, (node, index) => {
          return (
            <ImageBox key={index} title={node.title} image={node.image.fluid} />
          );
        })}
      </Wrapper>
    </Layout>
  );
};

Gallery.propTypes = {
  data: PropTypes.object.isRequired,
};

export const galleryQuery = graphql`
  {
    datoCmsGalleryPage {
      title
      gallery {
        title
        image {
          fluid(maxWidth: 1000, imgixParams: { fm: "jpg", auto: "compress" }) {
            ...GatsbyDatoCmsFluid
          }
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      featuredImage {
        fluid(maxWidth: 2000, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
  }
`;

export default Gallery;
