import styled from 'styled-components';
import Img from 'gatsby-image';
import media from '../../styles/MediaQueries';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 49%;
  margin-right: 2%;
  margin-bottom: 1.5em;
  background: ${props => props.theme.colors.grey};
  border: 3px solid black;
  &:nth-child(2n) {
    margin-right: 0;
  }
  ${media.tablet`
    width: 100%;
    margin-right: 0;
  `}
`;

export const ImageWrapper = styled.div`
  overflow: hidden;
  width: 100%;
  min-height: 350px;
  position: relative;
  ${media.tablet`
    min-height: 300px;
    border-right: none;
    border-bottom: 3px solid black;
  `}
  ${media.mobile`
    min-height: 225px;
  `}
`;

export const Image = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
  height: 100%;
  & > img {
    object-fit: 'cover' !important;
    object-position: '50% 50%' !important;
  }
`;
export const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 1.5em;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: ${props => props.theme.colors.grey};
  h2 {
    margin: 0;
  }
`;
