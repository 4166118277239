import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, ImageWrapper, Image, Content } from './ImageBox.css';

const ImageBox = ({ title, image }) => {
  return (
    <Wrapper>
      <ImageWrapper>
        <Image fluid={image} />
      </ImageWrapper>
      <Content>
        <h2>{title}</h2>
      </Content>
    </Wrapper>
  );
};

ImageBox.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
};

export default ImageBox;
